import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_view = _resolveComponent("header-view")!
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_view, { onMenuClick: _ctx.toggleSidebar }, null, 8, ["onMenuClick"]),
    (_ctx.authStore.$state.isSet)
      ? (_openBlock(), _createBlock(_component_side_bar, {
          key: 0,
          onMenuClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSidebar = false)),
          "show-sidebar": _ctx.showSidebar
        }, null, 8, ["show-sidebar"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "row",
      onClick: _cache[1] || (_cache[1] = ($event: any) => ( _ctx.showSidebar = false ))
    }, [
      _createElementVNode("main", {
        class: _normalizeClass(["col-md-9 ms-sm-auto col-lg-10 px-md-4", {backdrop: _ctx.showSidebar}])
      }, [
        _createVNode(_component_router_view),
        _createTextVNode(" version 1.0.11 ")
      ], 2)
    ])
  ], 64))
}