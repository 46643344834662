
import router from '@/router';
import { useAuthStore } from '@/stores/AuthStore';
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
export default class MyComponent extends Vue {
    @Prop(Boolean) readonly showSidebar!: boolean;
    verifyStore = useAuthStore();
    isPostApprovalAllowed = false
    mounted(): void {
        const loggedUser = this.verifyStore.$state.loggedUser;
        if (loggedUser.post)
            this.isPostApprovalAllowed = loggedUser.level == 1 && ["अध्यक्ष", "उपाध्यक्ष", "सचिव", "प्रदेशाध्यक्ष"].includes(loggedUser.post);
        else{
            this.verifyStore.logout();
            router.replace({name:'login'});
        }
    }
    nagigatetoRoute(name: string) {
        router.push({ name: name });
        this.$emit('menu-click');
    }
}
