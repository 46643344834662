
import { Options, Vue } from 'vue-class-component';
import HeaderView from './views/HeaderView.vue';
import SideBar from './components/SideBar.vue';
import { useAuthStore } from './stores/AuthStore';

@Options({
  components: {
    HeaderView,
    SideBar
  },
})
export default class App extends Vue {
  authStore  = useAuthStore();
  showSidebar = false;
  enableSidebar = false;
  created() {

    if (window.location.hash) {
      window.location.href = window.location.href.replace('/#', '');
    }

  }
  toggleSidebar() {
    this.showSidebar = !this.showSidebar
  }

}
